import {
  autoUpdate,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useTypeahead
} from '@floating-ui/react'

export function useListDropdown({ selectedIndex }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(null)
  const listRef = React.useRef([])
  const listContentRef = React.useRef([])
  const isTypingRef = React.useRef(false)

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(1),
      size({
        apply({ availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${elements.reference.getBoundingClientRect().width}px`,
            maxWidth: `${elements.reference.getBoundingClientRect().width}px`,
            maxHeight: `${availableHeight - 32}px`
          })
        },
      }),
    ]
  })

  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
  })

  const typeahead = useTypeahead(context, {
    listRef: listContentRef,
    activeIndex,
    selectedIndex,
    onMatch: setActiveIndex,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping
    }
  })

  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([dismiss, listNav, typeahead])

  return {
    isOpen,
    setIsOpen,
    context,
    getItemProps: (i, label, args = {}) => getItemProps({
      ...args,
      ref: (node) => {
        listRef.current[i] = node
        listContentRef.current[i] = label
      }
    }),
    activeIndex,
    listContentRef,
    listRef,
    getFloatingProps: (args = {}) => getFloatingProps({
      ...args,
      ref: refs.setFloating,
      style: floatingStyles,
    }),
    getReferenceProps: (args = {}) => getReferenceProps({
      ...getReferenceProps({
        ...args,
        ref: refs.setReference
      }),
    })
  }
}
