import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'

import iconLookingGlass from '/images/icons/icon-looking-glass.raw.svg'
import arrowIcon from '/images/icons/arrow.raw.svg'
import iconCross from '/images/icons/icon-cross.raw.svg'

import styles from './FilterSearchField.css'

export function FilterSearchField({ initialValue, onSubmit, layoutClassName = undefined, placeholder = undefined }) {
  const [input, setInput] = React.useState(initialValue || '')
  const { __ } = useTranslate()

  React.useEffect(
    () => {
      setInput(initialValue || '')
    },
    [initialValue]
  )

  return (
    <form className={cx(styles.component, layoutClassName)} onSubmit={handleSearchChange}>
      <Icon icon={iconLookingGlass} layoutClassName={styles.searchIconLayout} />
      <input
        type="search"
        className={styles.inputSearch}
        aria-label={__`search-form-field`}
        placeholder={placeholder || __`filter-search-placeholder`}
        onChange={e => setInput(e.currentTarget.value)}
        data-x='job-filter-search'
        value={input}
      />
      {input !== initialValue && (
        <button
          type='submit'
          className={styles.iconButton}
          data-x='click-to-search'
        >
          <Icon icon={arrowIcon} />
        </button>
      )}
      {input === initialValue && input !== '' && (
        <button
          type='button'
          className={styles.iconButton}
          data-x='click-to-search'
          onClick={e => {
            setInput('')
            onSubmit()
          }}
        >
          <Icon icon={iconCross} />
        </button>
      )}
    </form>
  )

  function handleSearchChange(e) {
    e.preventDefault()
    onSubmit(input)
  }
}
