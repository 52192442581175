import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'
import { HeadingSm } from '/features/buildingBlocks/Heading'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { ContainerLg } from '/features/buildingBlocks/Container'
import { FilterSearchField } from '/features/buildingBlocks/FilterSearchField'
import { OverviewFilters } from '/features/buildingBlocks/OverviewFilters'
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react'

import iconLookingGlass from '/images/icons/icon-looking-glass.raw.svg'
import iconFunnel from '/images/icons/funnel.raw.svg'
import iconCross from '/images/icons/cross.raw.svg'

import styles from './MobileFilters.css'

export function MobileFilters({
  filtersAndValues,
  handleFilterChange,
  handleResetClick,
  totalCount,
  onSearch = undefined,
  searchQuery = undefined,
  placeholder = undefined
}) {
  return (
    <div className={styles.component}>
      {typeof searchQuery === 'string' && onSearch && (
        <Search q={searchQuery} {...{ onSearch, placeholder }} />
      )}
      <Filters {...{ handleFilterChange, handleResetClick, filtersAndValues, totalCount }} />
    </div>
  )
}

function Filters({ handleFilterChange, handleResetClick, filtersAndValues, totalCount }) {
  const [isOpen, setIsOpen] = React.useState(false)
  const { __ } = useTranslate()

  const activeFiltersCount = getActiveFiltersCount({ filtersAndValues })

  return (
    <>
      <IconButton
        icon={iconFunnel}
        text={__`filters-title`}
        onClick={() => setIsOpen(true)}
        layoutClassName={styles.iconButtonLayout}
        dataX='open-filters-overlay'
      >
        {Boolean(activeFiltersCount) && (
          <FilterIndicator layoutClassName={styles.indicatorLayout} value={activeFiltersCount} />
        )}
      </IconButton>
      {isOpen && (
        <FloatingPortal>
          <FiltersOverlay
            onClose={() => { setIsOpen(false) }}
            {...{ handleFilterChange, handleResetClick, filtersAndValues, totalCount }}
          />
        </FloatingPortal>
      )}
    </>
  )

  function getActiveFiltersCount({ filtersAndValues }) {
    return Object.entries(filtersAndValues)
      .map(([id, options]) => {
        if (id === 'country') {
          return options.value === '' ? 0 : 1
        } else {
          return options.value.length
        }
      })
      .reduce(
        (acc, curr) => acc + curr
      )
  }
}

function FiltersOverlay({ handleFilterChange, handleResetClick, filtersAndValues, onClose, totalCount }) {
  return (
    <FloatingOverlay lockScroll>
      <div className={styles.component_rootFiltersOverlay}>
        <FiltersOverlayContent
          layoutClassName={styles.filtersOverlayContentLayout}
          {...{ handleFilterChange, handleResetClick, filtersAndValues, onClose, totalCount }}
        />
      </div>
    </FloatingOverlay>
  )
}

function FiltersOverlayContent({
  handleFilterChange,
  handleResetClick,
  filtersAndValues,
  onClose,
  layoutClassName,
  totalCount
}) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentFiltersOverlayContent, layoutClassName)}>
      <OverlayHeader title={__`filters-title`} {...{ onClose }} />
      <div className={styles.jobFilters}>
        <OverviewFilters
          onReset={handleResetClick}
          onFilterChange={handleFilterChange}
          {...{ filtersAndValues }}
        />
      </div>
      <div className={styles.resultsButton}>
        <ButtonPrimary
          layoutClassName={styles.resultsButtonLayout}
          onClick={onClose} dataX={'close-filter-overlay'}
          label={__(Number(totalCount), { totalCount })`job-filter-results-button-title`}
        />
      </div>
    </div>
  )
}

function OverlayHeader({ title, onClose }) {
  return (
    <div className={styles.componentOverlayHeader}>
      <HeadingSm h={2} {...{ title }} />
      <button
        className={styles.overlayHeaderButton}
        type="button"
        data-x="close-overlay"
        onClick={onClose}
      >
        <Icon icon={iconCross} />
      </button>
    </div>
  )
}

function Search({ q, onSearch, placeholder }) {
  const [isOpen, setOpen] = React.useState(false)
  const { __ } = useTranslate()
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setOpen
  })
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' })
  const { getFloatingProps } = useInteractions([
    dismiss
  ])

  return (
    <>
      <IconButton
        icon={iconLookingGlass}
        text={__`search-form-field`}
        onClick={() => setOpen(true)}
        layoutClassName={styles.iconButtonLayout}
        dataX='open-search-overlay'
      >
        {Boolean(q) && (
          <FilterIndicator layoutClassName={styles.indicatorLayout} value={1} />
        )}
      </IconButton>
      {isOpen &&
        <FloatingPortal>
          <SearchOverlay
            initialValue={q}
            onSubmit={(e) => {
              onSearch(e)
              setOpen(false)
            }}
            {...{ onSearch, refs, context, getFloatingProps, placeholder }}
          />
        </FloatingPortal>
      }
    </>
  )
}

function SearchOverlay({ initialValue, onSubmit, refs, context, getFloatingProps, placeholder = undefined }) {
  return (
    <FloatingOverlay>
      <FloatingFocusManager {...{ context }}>
        <div className={styles.component_rootSearchOverlay}>
          <ContainerLg>
            <div ref={refs.setFloating} {...getFloatingProps()}>
              <FilterSearchField
                {...{ onSubmit, initialValue, placeholder }}
              />
            </div>
          </ContainerLg>
        </div>
      </FloatingFocusManager>
    </FloatingOverlay>
  )
}

function IconButton({ dataX, icon, text, layoutClassName, onClick, children }) {
  return (
    <button
      type="button"
      className={cx(styles.componentIconButton, layoutClassName)}
      data-x={dataX}
      {... { onClick }}
    >
      {children}
      <span className={styles.iconButtonContent}>
        <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
        {text}
      </span>
    </button>
  )
}

function FilterIndicator({ layoutClassName, value }) {
  return (
    <div className={cx(styles.componentFilterIndicator, layoutClassName)}>
      { value ?? value }
    </div>
  )
}
